import React from 'react'
import {
    Accordion,
    Button, Card,
    FormControl,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Modal, Nav, ProgressBar,
    Tab,
    Tabs
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faTrash} from "@fortawesome/free-solid-svg-icons";

class CacheMenu extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            show: false,
            openedTab: 'behaviour',
            clearingCache: false,
            cachedFiles: [],
            cachedFilesWithSize: [],
            cachedSource: []
        }
    }

    componentDidMount = () => {
    }

    handleClose = () => this.setState({show: false})
    handleShow = () => {
        this.fetchCachedFiles()
        this.setState({
            show: true
        })
    }
    handleTabSwitch = (e) => console.log(e)

    fetchCachedFiles = () => {
        this.props.getCachedFiles(false).then((cachedFiles) => {
            this.setState({
                cachedFiles: cachedFiles.map((cachedFile) => (
                    decodeURIComponent(cachedFile.url)))
            },
                () => this.filterCachedFiles())
        })

    }

    filterCachedFiles = () => {
        let cachedSource = []
        for (const bookId in this.props.dataSource) {
            const book = this.props.dataSource[bookId]
            const baseUrl = book.baseUrl
            let cachedTime = 0
            let bookCachedTracks = 0
            let bookCachedSize = 0

            const cachedBookSource = book.source.map((track) => {
                if (this.isTrackCached(baseUrl+track.fileName)) {
                    cachedTime += track.duration
                    bookCachedTracks++
                    track.cached = true
                    bookCachedSize += track.size
                } else {
                    track.cached = false
                }
                return track
            })
            if (cachedTime>0) {
                book.cachedSize = bookCachedSize
                book.source = cachedBookSource
                book.cachedTracks = bookCachedTracks
                book.cachedTime = cachedTime
                cachedSource.push(book)
            }

        }
        this.setState({ cachedSource: cachedSource})
    }

    isTrackCached = (trackUrl) => {
        return this.state.cachedFiles.includes(trackUrl)
    }

    deleteCachedBook = async (e) => {
        this.setState({clearingCache: true})
        const book = this.state.cachedSource[e.target.dataset.bookIndex]
        const cache = await caches.open(this.props.cacheKey)

        await Promise.all(book.source.map((file) => {
            return cache.delete(book.baseUrl+file.fileName)
        })).then(() => this.handleCachedBookDeleted(e))
    }

    handleCachedBookDeleted = (e) => {
        // @TODO animate the transition a bit
        setTimeout(() => this.setState({clearingCache: false}, () => this.fetchCachedFiles()), 300)
    }

    // deleteCachedTrack = async (trackUrl) => {
    //     caches.open(this.props.cacheKey).then(function(cache) {
    //         cache.delete(trackUrl).then(function(response) {
    //             console.log(response)
    //         });
    //     })
    // }

    hrSize = (bytes) => {
        if (bytes === 0) return '0.00 o'
        const e = Math.floor(Math.log(bytes) / Math.log(1000))
        return `${(bytes / Math.pow(1000, e)).toFixed(2)} ${' KMGTP'.charAt(e)}o`
    }

    displayCachedBooks = () => this.state.cachedSource.length

    displayTotalCachedTracks = () => this.state.cachedSource.reduce((acc, curr) => acc+curr.cachedTracks, 0)

    displayTotalCachedTime = () => this.state.cachedSource.reduce((acc, curr) => acc+curr.cachedTime, 0)

    displayTotalCachedSize = () => this.state.cachedSource.reduce((acc, curr) => acc+curr.cachedSize, 0)

    displayTotalSize = () => this.state.cachedSource.reduce((acc, curr) => acc+curr.totalSize, 0)

    render = () => {
        return (
            <>
                <Button variant={"primary"} onClick={this.handleShow} className={"text-dark modalMenu"}><FontAwesomeIcon icon={faBox} /> Cache</Button>

                <Modal className={"bg-dark text-light plaubo"} show={this.state.show} onHide={this.handleClose} size={"xl"} id={"cacheMenu"} fullscreen={"xxl-down"}>
                    <Modal.Header className={"text-dark"} closeButton>
                        <Modal.Title as={"strong"}><FontAwesomeIcon icon={faBox}/> Cache</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"bg-dark text-light"}>
                        <Card className={"bg-dark text-light"}>
                            <Card.Header>
                                <Nav variant="tabs" activeKey={this.state.openedTab} onSelect={(k) => this.setState({openedTab: k})}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="behaviour">Comportement</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="storage">Stockage</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body className={"bg-dark text-light"}>
                                <Tabs activeKey={this.state.openedTab}>
                                    <Tab eventKey="behaviour" title="">
                                        {/*<p>Le cache est un espace de stockage sur votre appareil qui permet de stocker des fichiers temporairement. Chaque fois que vous lancez la lecture d'un chapitre, il est téléchargé une seule fois et stocké dans le cache. Les chapitres qui sont dans le cache peuvent être lus même lorsque l'appareil est hors-ligne.</p>*/}
                                        <p className={"mt-3"}>Le paramètre suivant détermine une durée de lecture que l'application téléchargera en avance. Il peut être utile de l'augmenter si vous utilisez l'application depuis une connexion peu fiable, en déplacement par exemple, pour ne pas interrompre la lecture si vous êtes dans une zone non-couverte lors du passage au chapitre suivant.</p>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Cache minimum</InputGroup.Text>
                                            <FormControl
                                                value={this.props.cacheThreshold}
                                                onChange={this.props.handleCacheThresholdUpdate}
                                                placeholder="30"
                                                aria-label="Durée en minutes"
                                                aria-describedby="basic-addon1"
                                            />
                                            <InputGroup.Text>minutes</InputGroup.Text>
                                        </InputGroup>
                                        <p>Le temps de lecture disponible dans le cache est représenté sur la barre de progression du livre par une zone hachurée qui empiète sur la durée restante.</p>
                                        <ProgressBar max={100}>
                                            <ProgressBar variant={"primary"} now="30" label="Écoulé" />
                                            <ProgressBar variant={"dark"} now="40" label="En cache" striped />
                                            <ProgressBar variant={"dark"} now="30" label="Restant" />
                                        </ProgressBar>
                                    </Tab>
                                    <Tab eventKey="storage" title="">
                                        <p>Taille du cache sur cet appareil: <strong>{this.hrSize(this.displayTotalCachedSize())}</strong>.</p>
                                        <Accordion flush>
                                            {this.state.cachedSource.map((book, index) => {
                                                return (
                                                    <>
                                                        <Accordion.Item key={index} eventKey={index} className={"bg-dark text-light"}>
                                                            <Accordion.Header className={"bg-dark text-light"}>{book.name}</Accordion.Header>
                                                            <Accordion.Body className={"bg-dark text-light"}>
                                                                <ListGroup variant={"flush"}>
                                                                    <ListGroupItem className={"text-clip bg-dark text-light"}>Source: <a href={book.baseUrl} target={"_blank"} rel={"noreferrer"}>{book.baseUrl}</a></ListGroupItem>
                                                                    <ListGroupItem className={"text-clip bg-dark text-light"}>Espace occupé: <strong>{this.hrSize(book.cachedSize)}</strong> en cache sur {this.hrSize(book.totalSize)}</ListGroupItem>
                                                                    <ListGroupItem className={"text-clip bg-dark text-light"}>Chapitres: <strong>{book.cachedTracks}</strong> en cache sur {book.source.length}</ListGroupItem>
                                                                    <ListGroupItem className={"text-clip bg-dark text-light"}>Temps de lecture: <strong>{this.props.formatDuration(book.cachedTime)} </strong>en cache sur {this.props.formatDuration(book.totalTime)}</ListGroupItem>
                                                                    <ListGroupItem className={"text-clip bg-dark text-light"}>
                                                                        <Button disabled={this.state.clearingCache} variant={"danger"} onClick={this.deleteCachedBook} data-book-index={index}><FontAwesomeIcon icon={faTrash}/> Supprimer ce livre du cache</Button>
                                                                    </ListGroupItem>
                                                                </ListGroup>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                )
                                            })}
                                        </Accordion>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className={"bg-dark text-light"}>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default CacheMenu
