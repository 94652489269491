import React from 'react'
import {
    Accordion,
    Button, Card,
    FormControl, FormSelect,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Modal, Nav, ProgressBar,
    Tab,
    Tabs
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpen, faBox, faPlay, faTrash} from "@fortawesome/free-solid-svg-icons";

class BookMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            openedTab: 'byAuthor',
            authors: {}
        }
    }

    handleClose = () => this.setState({show: false})
    handleShow = () => {
        this.filterDatasource()
        this.setState({
            show: true
        })
    }
    handleTabSwitch = (e) => console.log(e)

    componentDidMount = () => {
        // this.filterDatasource()
    }

    filterDatasource = () => {

        const datasource = this.props.datasource.map((book) => {
            let split = book.name.split(' - ')

            book.author = split[0]
            split = split.slice(1)
            book.title = [...split].join(' - ')
            return book
        })

        const authors = datasource.map((book) => book.author).filter((elem, index, self) => index === self.indexOf(elem))

        this.setState({
            authors: authors
        })
    }

    handlePlaylistChange = (e) => {
        let bookHash

        switch (e.target.nodeName) {
            case 'svg':
                bookHash = e.target.parentElement.dataset.hash
                break;
            case 'path':
                bookHash = e.target.parentElement.parentElement.dataset.hash
                break;
            case 'BUTTON':
            default:
                bookHash = e.target.dataset.hash
                break;
        }
        this.props.handlePlaylistChange(bookHash)
        this.handleClose()
    }

    render = () => {
        if (Object.keys(this.state.authors).length===0) return (
            <>
                <Button variant={"primary"} onClick={this.handleShow} className={"text-dark modalMenu"}><FontAwesomeIcon icon={faBookOpen} /> Livres</Button>
            </>
        )
        return (
            <>
                <Button variant={"primary"} onClick={this.handleShow} className={"text-dark modalMenu"}><FontAwesomeIcon icon={faBookOpen} /> Livres</Button>

                <Modal className={"bg-dark text-light plaubo"} show={this.state.show} onHide={this.handleClose} size={"xl"} id={"bookMenu"} fullscreen={"xxl-down"}>
                    <Modal.Header className={"text-dark"} closeButton>
                        <Modal.Title as={"strong"}><FontAwesomeIcon icon={faBookOpen}/> Choix du livre</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"bg-dark text-light"}>
                        <Card className={"bg-dark text-light"}>
                            <Card.Header>
                                <Nav variant="tabs" activeKey={this.state.openedTab} onSelect={(k) => this.setState({openedTab: k})}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="byAuthor">Auteurs</Nav.Link>
                                    </Nav.Item>
                                    {/*<Nav.Item>*/}
                                    {/*    <Nav.Link eventKey="storage">Stockage</Nav.Link>*/}
                                    {/*</Nav.Item>*/}
                                </Nav>
                            </Card.Header>
                            <Card.Body className={"bg-dark text-light"}>
                                <Tabs activeKey={this.state.openedTab}>
                                    <Tab eventKey="byAuthor" title="">
                                        <Accordion flush className={"bg-dark text-light"}>
                                        {this.state.authors.map((author, index) => {
                                            return (
                                                <>
                                                    <Accordion.Item key={index} eventKey={index} className={"bg-dark text-light"}>
                                                        <Accordion.Header className={"bg-dark text-light"}>{author}</Accordion.Header>
                                                        <Accordion.Body key={index+'_body'} className={"bg-dark text-light"}>
                                                            <ListGroup variant={"flush"}>
                                                                {this.props.datasource.filter((book) => book.author===author).map((book) => {
                                                                    return (
                                                                    <>
                                                                        <ListGroupItem key={book.hash} className={"text-wrap bg-dark text-light"}><Button size={"sm"} variant={"primary"} onClick={this.handlePlaylistChange} data-hash={book.hash} className={"text-dark"}><FontAwesomeIcon icon={faPlay} /></Button>
                                                                            <span className={"bookTitle"}>{book.title}</span>
                                                                        </ListGroupItem>
                                                                    </>
                                                                    )
                                                                })}
                                                            </ListGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            )
                                        })}
                                        </Accordion>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className={"bg-dark text-light"}>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default BookMenu
