import React from 'react'
import ReactHowler from 'react-howler'
import raf from 'raf' // requestAnimationFrame polyfill
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlay, faPause, faStepBackward, faStepForward,
    faVolumeDown, faVolumeUp, faVolumeOff,
    faTachometerAlt, faHourglassHalf, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import {
    ButtonGroup,
    Col, Container,
    FormSelect,
    Nav,
    ProgressBar,
    Row,
    Spinner
} from "react-bootstrap";
import CacheMenu from "./CacheMenu";
import BookMenu from "./BookMenu";

class Plaubo extends React.Component {
    constructor (props) {
        super(props)

        this.appId = 'plaubo'
        this.appLabel = 'Plaubo'
        this.config = 'data/plaubo.config.json'
        this.VERSION = '1.0.4'
        this.endpoint = ''
        this.defaultPlaylistHash = "09243d5a4f06a4b1073f7dc9c9e01450"
        this.cacheKey = 'PLAUBO_MEDIA'

        this.allSources = []
        this.saveTimer = null
        this.cacheTimer = null
        this.saveTimerInterval = 1000

        this.skipInterval = 5

        this.fetching = false

        this.state = {
            source: [],
            sourceLabel: {
                full: '',
                title: '',
                album: '',
                artist: ''
            },
            autoplay: true,
            currentPlaylistIndex: 0,
            currentPlaylistHash: this.defaultPlaylistHash,
            currentSrcIndex: 0,
            playing: false,
            loaded: false,
            loop: false,
            mute: false,
            volume: 1.0,
            seek: 0.0,
            rate: 1,
            isSeeking: false,
            message: null,
            messageType: 'danger',
            chapterTiming: {
                past: 0,
                future: 0,
                cached: 0,
                total: 0
            },
            cacheThreshold: 30, // minutes
            // cachedFiles: [],
            saveTimerInterval: 1000, // milliseconds
            cacheTimerInterval: 5000, // milliseconds
            fetching: false,
            fakeAudioStarted: false
        }

        this.handleToggle = this.handleToggle.bind(this)
        this.handleOnLoad = this.handleOnLoad.bind(this)
        this.handleOnEnd = this.handleOnEnd.bind(this)
        this.handleOnPlay = this.handleOnPlay.bind(this)
        this.handleStop = this.handleStop.bind(this)
        this.renderSeekPos = this.renderSeekPos.bind(this)
        this.handleLoopToggle = this.handleLoopToggle.bind(this)
        this.handleMuteToggle = this.handleMuteToggle.bind(this)
        this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this)
        this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this)
        this.handleSeekingChange = this.handleSeekingChange.bind(this)
        this.handleRate = this.handleRate.bind(this)
        this.handlePrevious = this.handlePrevious.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handlePlaylistChange = this.handlePlaylistChange.bind(this)
        this.saveState = this.saveState.bind(this)
        this.clearMessage = this.clearMessage.bind(this)
        this.handleAutoplayToggle = this.handleAutoplayToggle.bind(this)
        this.handleCacheThresholdUpdate = this.handleCacheThresholdUpdate.bind(this)
    }

    componentDidMount () {
        this.fetchConfig()
            .then(() => this.fetchDataSource()
                .then(() => this.loadSavedState()
            )
        )
        this.setupFakeAudio()
    }

    componentWillUnmount () {
        this.clearRAF()
        clearTimeout(this.saveTimer)
        clearTimeout(this.cacheTimer)
    }

    setupFakeAudio = () => {
        this.fakeaudio = document.createElement('audio')
        this.fakeaudio.src = '/10-seconds-of-silence.mp3'
        this.fakeaudio.preload = "true"
        this.fakeaudio.controls = "false"
        this.fakeaudio.loop = "true"
        // this.fakeaudio.rate = 1
        this.fakeaudio.id = "fakeaudio"
        // document.getElementById('root').appendChild(this.fakeaudio)
        document.body.appendChild(this.fakeaudio)
    }


    fetchConfig = async () => {
        const response = await fetch(this.config).then((response) => response.json())
        this.endpoint = response.endpoint
        this.VERSION = response.version
        window.Plaubo = {}
        window.Plaubo = response
    }

    fetchDataSource = async () => {
        this.allSources = await fetch(this.endpoint)
            .then(response => response.json())
    }

    setupSaveTimer = () => {
        clearTimeout(this.saveTimer)
        this.saveTimer = setInterval(() => this.saveState(), this.state.saveTimerInterval)
    }

    setupCacheTimer = () => {
        clearTimeout(this.cacheTimer)
        this.cacheTimer = setInterval(() => this.cacheUpdate(), this.state.cacheTimerInterval)
    }

    cacheUpdate = () => {
        if (!('caches' in window)) return
        const minCache = 60*this.state.cacheThreshold
        const cacheLeft = Math.max(0, this.state.chapterTiming.cached-this.state.seek)
        if (cacheLeft<=minCache) {
            // console.log('Cache left: '+ cacheLeft +' is below threshold of '+minCache+', fetching next track...')
            if (this.state.currentSrcIndex<this.state.source.length) {
                if (this.state.fetching) {
                    // console.log('Already fetching next track...')
                } else {
                    clearTimeout(this.cacheTimer)
                    this.setState({ fetching: true })
                    const currentSource = this.allSources[this.state.currentPlaylistIndex]
                    let nextTracksToFetch = []
                    // build a list of the next chapters adding up their length to get to minCache
                    currentSource.source.slice(this.state.currentSrcIndex).reduce((acc, val) => {
                        if (acc > minCache) {
                            // console.log(`${val.fileName} (${val.duration}) ignored, ${acc}/${minCache} already above threshold`)
                            return acc
                        } else {
                            // console.log(`${val.fileName} (${val.duration} + ${acc}) added, total now ${val.duration+acc} / ${minCache}`)
                            nextTracksToFetch.push(currentSource.baseUrl + val.fileName)
                            return acc + val.duration
                        }
                    }, -this.state.seek)

                    // fetch each new file sequentially to minimize individual download time
                    const cacheAdditions = (async () => {
                        for (let addFileToCache of nextTracksToFetch.map(url => () => this.addUrlToCache(url))) await addFileToCache()
                    })()

                    cacheAdditions
                        .then(() => this.getChapterTiming('cached'))
                        .finally(() => {
                            // this.setupCacheTimer()
                            setTimeout(() => this.setState({ fetching: false }), 700)
                            // this.setState({
                            //     cachedFiles: this.getCachedFiles(false)
                            // })
                        })
                }
            } else {
                // console.log('Last track already cached, nothing more to fetch')
            }
        } else {
            // console.log('Cache left: '+ cacheLeft +' is above threshold of '+minCache)
        }
    }

    addUrlToCache = async (url) => caches.open(this.cacheKey).then(cache => cache.add(url).then(() => (this.setupCacheTimer())))

    getCachedFiles = async (currentSourceOnly=true) => {
        if (!('caches' in window)) return []
        let fileNames = []
        const currentSource = this.allSources[this.state.currentPlaylistIndex]
        const baseUrl = currentSource.baseUrl
        const cache = await caches.open(this.cacheKey)
        const matches = await cache.matchAll()

        await Promise.all(
            matches.map(async (response) => {
                if (currentSourceOnly) {
                    if (decodeURIComponent(response.url).substr(0, baseUrl.length) === baseUrl) {
                        fileNames.push(response)
                    }
                } else {
                    fileNames.push(response)
                }
            })
        )

        return fileNames
    }

    getChapterTiming (type) {
        (async () => {
            const currentSource = this.allSources[this.state.currentPlaylistIndex]
            switch (type) {
                case 'past':
                    return await currentSource.source
                        .slice(0, this.state.currentSrcIndex)
                        .reduce((prev, curr) => (prev + curr.duration), this.state.seek)
                    // eslint-disable-next-line no-unreachable
                    break;
                case 'future':
                    return await currentSource.source
                            .slice(this.state.currentSrcIndex)
                            .reduce((prev, curr) => (prev + curr.duration), 0)
                        - this.state.seek
                    // eslint-disable-next-line no-unreachable
                    break;
                case 'cached':
                    if (!('caches' in window)) return 0
                    clearInterval(this.cacheTimer)
                    return this.getCachedFiles()
                        .then((cachedFiles) => {
                            return cachedFiles.map((response) => {
                                return decodeURIComponent(response.url)
                            })
                        })
                        .then((cachedFiles) => {
                            let counter = 0
                            let contiguous = true
                            return currentSource.source
                                .slice(this.state.currentSrcIndex)
                                .reduce((acc, val, index) => {
                                    // we must only count contiguous cached files, if we have a gap in the buffer then the displayed value and the effective cache won't be in sync
                                    if (counter<index) contiguous = false
                                    // if (cachedFiles.includes(currentSource.baseUrl+val.fileName) && contiguous) console.log(`Cached: ${val.fileName}, c/i ${counter}/${index}, contiguous ${contiguous}`)
                                    if (cachedFiles.includes(currentSource.baseUrl+val.fileName) && contiguous) {
                                        counter++
                                        return acc + val.duration
                                    } else {
                                        return acc
                                    }
                                }, 0)
                        })
                    // eslint-disable-next-line no-unreachable
                    break;
                case 'total':
                default:
                    return await this.allSources[this.state.currentPlaylistIndex].source.reduce((prev, curr) => (prev + curr.duration), 0)
                    // eslint-disable-next-line no-unreachable
                    break;
            }
            // console.log(type, time)
            // return time
        })().then((time) => {
            // console.log(type, time)
            let timings = this.state.chapterTiming
            switch (type) {
                case 'past':
                    timings.past = time
                    break;
                case 'future':
                    timings.future = time
                    break;
                case 'cached':
                    // console.log(`Cached: ${time}`)
                    timings.cached = time
                    break;
                case 'total':
                default:
                    timings.total = time
                    break;
            }
            this.setState({
                chapterTiming: timings
            }, () => {
                if (type==='cached') this.setupCacheTimer()
            })
        })

    }

    getChapterTimings() {
        this.getChapterTiming('past')
        this.getChapterTiming('future')
        this.getChapterTiming('total')
        this.getChapterTiming('cached')
    }

    handlePlaylistChange (bookHash) {
        this.handleStop()

        const newPlaylistHash = bookHash
        const newPlaylistIndex = parseInt(this.getPlaylistIdFromHash(newPlaylistHash))

        console.log(newPlaylistHash, newPlaylistIndex)

        this.setState({
            loaded: false,
            playing: false,
            currentSrcIndex: 0,
            seek: 0,
            currentPlaylistHash: newPlaylistHash,
            currentPlaylistIndex: newPlaylistIndex,
        }, () => (
            this.setSource()
        ))
    }

    getPlaylistIdFromHash(hash) {
        for (let i in this.allSources) {
            if (this.allSources[i].hash === hash) return i;
        }
    }

    setSource () {
        const newSource = this.allSources[this.state.currentPlaylistIndex].source.map((e) => {
            return this.allSources[this.state.currentPlaylistIndex].baseUrl + e.fileName
        })

        this.setState({
            source: newSource
        }, () => {
            this.player.seek(this.state.seek)
            this.getChapterTimings()
            this.updateCurrentlyPlaying()
        })
    }

    updateCurrentlyPlaying () {
        const newSourceLabel = this.allSources[this.state.currentPlaylistIndex].name
        const split = newSourceLabel.split(" - ");
        const title = `Chapitre ${this.state.currentSrcIndex + 1} sur ${this.state.source.length}`
        const artist = split[0]
        const album = split[split.length-1]
        const chapter = `${this.state.currentSrcIndex + 1}/${this.state.source.length}`

        this.setState({
            sourceLabel: {
                full: newSourceLabel,
                title: title,
                album: album,
                artist: artist,
                chapter: chapter
            }
        })
    }

    loadSavedState () {
        const savedVersion = this.load('version')===null? 'none': this.load('version')
        let sameVersion = savedVersion===this.VERSION

        // @TODO BC stuff should be moved elsewhere
        // v0.9.1 and prior didn't use hashes to uniquely identify books, this array maps old ids to new hashes
        const oldIds = ["09243d5a4f06a4b1073f7dc9c9e01450", "db5635cd8d6346c00f36afe99f61a32b", "f7dcc5fb00bc41d26d4191a77f1cb561", "5ccce664d7cef021032a0b19890d3d11", "30252c19df853b912dce72570bc286e9", "4cac80e60d2fd8d30b433b3b08dc64b2"]
        if (!sameVersion && savedVersion==='0.9.1') {
            if (this.load('currentPlaylistHash')===null && this.load('currentPlaylistIndex')!=null) {
                this.save('currentPlaylistHash', oldIds[this.load('currentPlaylistIndex', 'int')])
                this.save('currentPlaylistIndex', this.getPlaylistIdFromHash(this.load('currentPlaylistHash')))
            }
            sameVersion = true
        }

        // ignore version difference if versions are compatible
        const compat = ['0.9.2', '0.9.3', '0.9.4', '0.9.5', '0.9.6', '0.9.7', '0.9.8', '0.9.9', '1.0.0', '1.0.1', '1.0.2', '1.0.3', '1.0.4']
        if (!sameVersion && compat.includes(this.VERSION) && compat.includes(savedVersion)) sameVersion = true

        let savedCurrentPlaylistHash = this.state.currentPlaylistHash

        // if no saved state found
        if (this.load('currentPlaylistHash')===null) {
            // load default value
            savedCurrentPlaylistHash = this.defaultPlaylistHash
        } else {
            // if same version as stored one
            if (sameVersion) {
                savedCurrentPlaylistHash = this.load('currentPlaylistHash')
            } else { /* try to convert old version to new one */ }
        }

        const savedCurrentPlaylistIndex = this.getPlaylistIdFromHash(savedCurrentPlaylistHash)

        const savedCurrentSrcIndex = sameVersion? this.load('currentSrcIndex')===null? this.state.currentSrcIndex: this.load('currentSrcIndex', 'int'): 0
        const savedVolume = sameVersion? this.load('volume')===null? this.state.volume: this.load('volume', 'float'): 1
        const savedRate = sameVersion? this.load('rate')===null? this.state.rate: this.load('rate', 'float'): 1
        const savedSeek = sameVersion? this.load('seek')===null? 0: this.load('seek', 'float'): 0
        const savedAutoplay = sameVersion? this.load('autoplay')===null? true: this.load('autoplay', 'bool'): true
        const savedCacheThreshold = sameVersion? this.load('cacheThreshold')===null? this.state.currentSrcIndex: this.load('cacheThreshold', 'int'): 30

        this.setState({
            currentPlaylistIndex: savedCurrentPlaylistIndex,
            currentPlaylistHash: savedCurrentPlaylistHash,
            currentSrcIndex: savedCurrentSrcIndex,
            volume: savedVolume,
            rate: savedRate,
            seek: savedSeek,
            autoplay: savedAutoplay,
            cacheThreshold: savedCacheThreshold
        }, () => ( this.setSource() )
        )

        this.setupSaveTimer()
        this.setupCacheTimer()
        this.getChapterTimings()
    }

    load (itemKey, itemType='string') {
        const value = localStorage.getItem(this.appId+':'+itemKey)
        switch (itemType) {
            case 'float':
                return parseFloat(value)
                // eslint-disable-next-line no-unreachable
            break;
            case 'int':
                return parseInt(value)
                // eslint-disable-next-line no-unreachable
            break;
            case 'bool':
                return value===true || value==='true'
                // eslint-disable-next-line no-unreachable
            break;
            case 'string':
            default:
                return value
                // eslint-disable-next-line no-unreachable
            break;
        }
    }

    save (itemKey, itemValue) {
        return localStorage.setItem(this.appId+':'+itemKey, itemValue)
    }

    saveState () {
        this.save('seek', this.state.seek)
        this.save('currentPlaylistHash', this.state.currentPlaylistHash)
        this.save('currentPlaylistIndex', this.state.currentPlaylistIndex)
        this.save('currentSrcIndex', this.state.currentSrcIndex)
        this.save('volume', this.state.volume)
        this.save('rate', this.state.rate)
        this.save('autoplay', this.state.autoplay)
        this.save('cacheThreshold', this.state.cacheThreshold)
        this.save('version', this.VERSION)
        if (this.isPlaying()) {
            this.getChapterTiming('past')
            this.getChapterTiming('future')
        }

        if ('mediaSession' in navigator) {
            navigator.mediaSession.setPositionState({
                duration: this.player.duration(),
                playbackRate: this.player.rate(),
                position: this.player.seek()
            })
        }
    }

    isPlaying () {
        return this.state.playing===true;
    }

    handleToggle () {
        if (!this.state.fakeAudioStarted) {
            this.fakeaudio.play()
            this.setState({fakeAudioStarted: true})
        }

        switch (this.isPlaying()) {
            case true:
                this.handlePause()
                break;
            case false:
            default:
                this.handlePlay()
                break;
        }

    }

    handlePlay (e=null) {
        // console.log(e)
        this.setState({ playing: true })
        this.fakeaudio.play()
        this.exposeMediaControls()
    }

    handlePause (e=null) {
        // console.log(e)
        this.setState({ playing: false })
        this.fakeaudio.pause()
        this.exposeMediaControls()
    }

    handleOnLoad () {
        this.setState({
            loaded: true,
            duration: this.player.duration()
        })
        this.exposeMediaControls()
    }

    handleOnPlay () {
        this.setState({
            playing: true
        })

        this.exposeMediaControls()
        this.renderSeekPos()
    }

    handleOnEnd () {
        this.setState({
            playing: false
        })

        this.exposeMediaControls()
        this.clearRAF()
        this.handleAutoPlay()
    }

    handleAutoPlay () {
        const bookName = this.allSources[this.state.currentPlaylistIndex].name

        if (this.state.autoplay) {
            if (this.state.currentSrcIndex < this.state.source.length - 1) {
                this.handleNext()
                this.handleOnPlay()
                const chapterNumber = parseInt(this.state.currentSrcIndex+1)
                this.setMessage(bookName+': Chapitre '+chapterNumber+' lancé automatiquement', 'info')
            } else {
                this.setMessage(bookName+': livre terminé!', 'primary')
            }
        } else {
            this.handleNext()
            this.handleStop()
            const chapterNumber = parseInt(this.state.currentSrcIndex+1)
            this.setMessage(bookName+': Chapitre '+chapterNumber+' chargé, en pause', 'info')
        }
    }

    handleStop (e=null) {
        this.player.stop()
        this.setState({
            playing: false, // Need to update our local state so we don't immediately invoke autoplay
            seek: 0
        }, () => {
            this.saveState()
            this.exposeMediaControls()
        })
    }

    handleAutoplayToggle () {
        this.setState({
            autoplay: !this.state.autoplay
        })
    }

    handleLoopToggle () {
        this.setState({
            loop: !this.state.loop
        })
    }

    handleMuteToggle () {
        this.setState({
            mute: !this.state.mute
        })
    }

    handleMouseDownSeek () {
        this.setState({
            isSeeking: true,
            playing: false
        })
    }

    handleMouseUpSeek (e) {
        // console.log(e.target.value)
        this.setState({
            isSeeking: false,
            playing: true,
            seek: parseFloat(e.target.value)
        }, () => {
            this.getChapterTiming('past')
            this.getChapterTiming('future')
            this.getChapterTiming('cached')
            this.exposeMediaControls()
        })

        this.player.seek(parseFloat(e.target.value))
    }

    handleSeekingChange (e) {
        // console.log(e.target.value)
        this.setState({
            seek: parseFloat(e.target.value)
        }, () => {
            this.getChapterTiming('past')
            this.getChapterTiming('future')
            this.getChapterTiming('cached')
            this.exposeMediaControls()
        })

        this.player.seek(parseFloat(e.target.value))
    }

    handleSeekTo (e=null) {
        console.log(e)
        this.displayMessage(`${e.action}, ${e.seekTime}`)
        // this.handleSeekingChange({target:{value: e}})
    }

    handleSeekForward (e=null) {
        const toSeek = Math.min(this.state.seek+this.skipInterval, this.state.duration)
        this.handleSeekingChange({target: {value: toSeek}})
    }

    handleSeekBackward (e=null) {
        const toSeek = Math.max(this.state.seek-this.skipInterval, 0)
        this.handleSeekingChange({target: {value: toSeek}})
    }

    renderSeekPos () {
        if (!this.state.isSeeking) {
            this.setState({
                seek: this.player.seek()
            })
        }
        if (this.state.playing) {
            this._raf = raf(this.renderSeekPos)
        }

    }

    handleRate (e) {
        const rate = parseFloat(e.target.value)
        this.player.rate(rate)
        this.setState({ rate })
    }

    handleCacheThresholdUpdate (e) {
        const cacheThreshold = parseInt(e.target.value)
        this.setState({
            cacheThreshold: cacheThreshold
        })
    }

    clearRAF () {
        raf.cancel(this._raf)
    }

    handlePrevious (e=null) {
        const nextIndex = this.state.currentSrcIndex === 0 ? 0 : this.state.currentSrcIndex-1
        this.setSrcIndex(nextIndex)
    }

    handleNext (e=null) {
        const nextIndex = this.state.currentSrcIndex === this.state.source.length-1 ? this.state.source.length-1 : this.state.currentSrcIndex+1
        this.setSrcIndex(nextIndex)
    }

    setSrcIndex (nextIndex) {
        this.setState({
            loaded: false,
            currentSrcIndex: nextIndex,
            seek: 0,
        }, () => {
            this.getChapterTimings()
            this.exposeMediaControls()
            this.updateCurrentlyPlaying()
        })
    }

    formatDuration (time) {
        // console.log(this.state)
        // let time = timeArg
        // if (timeArg===null) {
        //     time = !this.state.loaded? this.allSources[this.state.currentPlaylistIndex].source[this.state.currentSrcIndex].duration: this.state.duration
        // }
        let sec_num = parseInt(time, 10)
        let hours   = Math.floor(sec_num / 3600)
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
        let seconds = sec_num - (hours * 3600) - (minutes * 60)
        let output = ''

        if ((hours<1 && minutes<1 && seconds<1)) return '0s';

        if (hours>0) output+=hours+'h '
        if (minutes>0) output+=minutes+'m '
        if (seconds>0) output+=seconds+'s'

        return output
    }

    displayMessage () {
        if (this.state.message!==null) {
            return (
                <div role="alert" className={"fade mb-2 mt-4 alert show alert-"+this.state.messageType}>{this.state.message}
                    <button type="button" className="btn-close float-end" aria-label="Close alert" onClick={this.clearMessage} />
                </div>
            )
        }
    }

    setMessage (message, messageType='danger') {
        this.setState({
            message: message,
            messageType: messageType
        })
    }

    clearMessage () {
        this.setMessage(null)
    }

    exposeMediaControls () {
        if ('mediaSession' in navigator) {

            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: this.state.sourceLabel.title,
                artist: this.state.sourceLabel.artist,
                album: this.state.sourceLabel.album,
                artwork: [
                    {
                        "src": "icon64.png",
                        "type": "image/png",
                        "sizes": "64x64"
                    },
                    {
                        "src": "icon128.png",
                        "type": "image/png",
                        "sizes": "128x128"
                    },
                    {
                        "src": "icon512.png",
                        "type": "image/png",
                        "sizes": "512x512"
                    },
                ]
            })

            // console.log(navigator.mediaSession.metadata)

            navigator.mediaSession.playbackState = this.isPlaying()? "playing": "paused"

            navigator.mediaSession.setPositionState({
                duration: this.player.duration(),
                playbackRate: this.player.rate(),
                position: this.player.seek()
            })

            navigator.mediaSession.setActionHandler('play', (e) => {this.handlePlay(e)})
            navigator.mediaSession.setActionHandler('pause', (e) => {this.handlePause(e)})
            navigator.mediaSession.setActionHandler('stop', (e) => {this.handleStop(e)})
            navigator.mediaSession.setActionHandler('seekbackward', (e) => {this.handleSeekBackward(e)})
            navigator.mediaSession.setActionHandler('seekforward', (e) => {this.handleSeekForward(e)})
            navigator.mediaSession.setActionHandler('seekto', (e) => {this.handleSeekTo(e)})
            navigator.mediaSession.setActionHandler('previoustrack', (e) => {this.handlePrevious(e)})
            navigator.mediaSession.setActionHandler('nexttrack', (e) => {this.handleNext(e)})
            // navigator.mediaSession.setActionHandler('skipad', function() { /* Code excerpted. */ })

            // console.log(navigator.mediaSession)
        }
    }

    progressBar () {
        if (this.state.fetching) {
            return (
                <ProgressBar max={100} className={"mt-3 mb-3 bg-dark clearfix"}>
                    <ProgressBar variant={"primary"} now={this.state.chapterTiming.past/this.state.chapterTiming.total*100} label={this.formatDuration(this.state.chapterTiming.past)} />
                    <ProgressBar variant={"dark"} now={(this.state.chapterTiming.cached-this.state.seek)/this.state.chapterTiming.total*100} animated />
                    <ProgressBar variant={"dark"} now={(this.state.chapterTiming.future-this.state.chapterTiming.cached+this.state.seek)/this.state.chapterTiming.total*100} label={this.formatDuration(this.state.chapterTiming.future)} />
                </ProgressBar>
            )
        } else {
            return (
                <ProgressBar max={100} className={"mt-3 mb-3 bg-dark clearfix"}>
                    <ProgressBar variant={"primary"} now={this.state.chapterTiming.past/this.state.chapterTiming.total*100} label={this.formatDuration(this.state.chapterTiming.past)} />
                    <ProgressBar variant={"dark"} now={(this.state.chapterTiming.cached-this.state.seek)/this.state.chapterTiming.total*100} striped />
                    <ProgressBar variant={"dark"} now={(this.state.chapterTiming.future-this.state.chapterTiming.cached+this.state.seek)/this.state.chapterTiming.total*100} label={this.formatDuration(this.state.chapterTiming.future)} />
                </ProgressBar>
            )
        }
    }

    render () {
        if (this.state.source.length===0) {
            return (
                <div className='wrapper'>
                    <div className='card plaubo bg-dark text-light'>
                        <div className='card-header'>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Nav className="align-content-start" variant="pills" defaultActiveKey="#first">
                                            <Nav.Item>
                                                <Nav.Link id={"appName"} disabled>{this.appLabel}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col>
                                        <Nav className="justify-content-end">
                                            <Nav.Item>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='player card-body loading'>
                            <Spinner size="sm" variant="light" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> Chargement...
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='wrapper'>
                    <div className='card plaubo bg-dark text-light'>
                        <div className='card-header'>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Nav className="align-content-start" variant="pills" defaultActiveKey="#first">
                                            <Nav.Item>
                                                <Nav.Link id={"appName"} disabled>{this.appLabel}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col>
                                        <Nav className="justify-content-end">
                                            <Nav.Item>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='player card-body loaded'>
                            <ReactHowler
                                src={this.state.source[this.state.currentSrcIndex]}
                                playing={this.state.playing}
                                onLoad={this.handleOnLoad}
                                onPlay={this.handleOnPlay}
                                onEnd={this.handleOnEnd}
                                loop={this.state.loop}
                                mute={this.state.mute}
                                volume={this.state.volume}
                                // html5={true}
                                ref={(ref) => (this.player = ref)}
                            />




                            <div id={"currentlyPlaying"}>
                                <Button onClick={this.handleToggle} size={"lg"} disabled={(this.state.loaded) ? '' : 'disabled'}>
                                    {(this.state.playing) ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
                                </Button>
                                <div className={"text-light text-clip"}>
                                {(this.state.loaded) ? (this.state.playing)
                                ? <Spinner size="sm" variant="light" animation="grow" role="status"><span className="visually-hidden">Playing</span></Spinner>
                                : <FontAwesomeIcon icon={faCheckCircle} />
                                : <Spinner size="sm" variant="light" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>} {this.state.sourceLabel.album} {this.state.sourceLabel.chapter}<br/>{this.state.sourceLabel.artist}</div>
                            </div>
                            <div className={"clearfix"}></div>
                            {this.progressBar()}
                            <ButtonGroup>
                                <Button onClick={this.handlePrevious}
                                        disabled={(this.state.currentSrcIndex === 0)}><FontAwesomeIcon
                                    icon={faStepBackward}/></Button>
                                <Button onClick={this.handleNext}
                                        disabled={(this.state.currentSrcIndex === this.state.source.length - 1)}><FontAwesomeIcon
                                    icon={faStepForward}/></Button>
                            </ButtonGroup>
                             
                            <label className="switch">
                                <input name="autoplay"
                                       type="checkbox"
                                       id="autoplay"
                                       checked={this.state.autoplay}
                                       onChange={this.handleAutoplayToggle} />
                                <span className="slider round"></span>
                            </label>
                            <label htmlFor={"autoplay"}> Lecture automatique</label>
                            <br/>
                            {this.displayMessage()}
                            <hr/>
                            <div className='seek'>
                                <label htmlFor="seekRange" className="form-label"><FontAwesomeIcon
                                    icon={faHourglassHalf}/> {this.formatDuration(this.state.seek.toFixed())}
                                    {' / '}
                                    {(this.state.duration) ? this.formatDuration(this.state.duration) : this.formatDuration(this.allSources[this.state.currentPlaylistIndex].source[this.state.currentSrcIndex].duration)} </label>
                                <input
                                    type="range"
                                    className="form-range"
                                    id="seekRange"
                                    min='0'
                                    max={this.state.duration ? this.state.duration.toFixed(2) : 0}
                                    step='.01'
                                    value={parseFloat(this.state.seek)}
                                    onChange={this.handleSeekingChange}
                                    onMouseDown={this.handleMouseDownSeek}
                                    onMouseUp={this.handleMouseUpSeek}
                                    onTouchMove={this.handleSeekingChange}
                                    onTouchStart={this.handleMouseDownSeek}
                                    onTouchEnd={this.handleMouseUpSeek}
                                    disabled={(this.state.loaded) ? '' : 'disabled'}
                                />
                            </div>

                            <div className='volume'>
                                <label htmlFor="volumeRange" className="form-label"><FontAwesomeIcon
                                    icon={this.state.volume >= 0.6 ? faVolumeUp : this.state.volume >= 0.2 ? faVolumeDown : faVolumeOff}/> {parseInt(this.state.volume.toFixed(2) * 100)}%</label>
                                <input
                                    type="range"
                                    className="form-range"
                                    id="volumeRange"
                                    min='0'
                                    max='1'
                                    step='.05'
                                    value={this.state.volume}
                                    onChange={e => this.setState({volume: parseFloat(e.target.value)})}
                                    disabled={(this.state.loaded) ? '' : 'disabled'}
                                />
                            </div>

                            <div className='rate'>
                                <label htmlFor="rateRange" className="form-label"><FontAwesomeIcon
                                    icon={faTachometerAlt}/> {parseInt(this.state.rate.toFixed(2) * 100)}%</label>
                                <input
                                    type="range"
                                    className="form-range"
                                    id="rateRange"
                                    min='0.1'
                                    max='3'
                                    step='.1'
                                    value={this.state.rate}
                                    onChange={this.handleRate}
                                    disabled={(this.state.loaded) ? '' : 'disabled'}
                                />
                            </div>


                            <div className='mb-2'>
                                <em id={"versionNumber"}>v {this.VERSION}</em>
                                <BookMenu
                                    datasource={this.allSources}
                                    handlePlaylistChange={this.handlePlaylistChange}
                                    currentPlaylistHash={this.state.currentPlaylistHash}
                                    formatDuration={this.formatDuration}
                                />
                                 
                                <CacheMenu
                                    cacheKey={this.cacheKey}
                                    dataSource={this.allSources}
                                    getCachedFiles={this.getCachedFiles}
                                    formatDuration={this.formatDuration}
                                    cacheThreshold={this.state.cacheThreshold}
                                    handleCacheThresholdUpdate={this.handleCacheThresholdUpdate}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Plaubo
